<template>
  <div>
    <b-list-group-item >
      <b-row>
        <b-col class="menu-tab">
          <p v-b-toggle.collapse-3>{{ topping.name }}</p>
        </b-col>
        <b-col>
          <b-button-toolbar style="float:right">
            <b-button-group class="mx-1">
              <b-button           v-if=" $hasPermission('manage menues') " 
              @click="addNew" variant="success"><i class="flaticon2-add-1" ></i>Add Topping</b-button>
              <b-button           v-if=" $hasPermission('manage menues') " 
              @click="editGroup(topping.id)">Edit</b-button>
              <b-button           v-if=" $hasPermission('manage menues') " 
              @click="deleteGroup(topping.id)" variant="danger">Delete</b-button>
            </b-button-group>
          </b-button-toolbar>
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-collapse visible id="collapse-3">
      <b-card>
        <b-list-group>
          <b-list-group-item v-for="item in topping.items">
            <b-row>
              <b-col>
                {{ item.id }} # {{ item.name }} - {{$store.state.auth.currency}}{{ item.price }}
              </b-col>
              <b-col>
                <b-button-toolbar style="float:right">
                  <b-button-group size="sm">
                    <b-button           v-if=" $hasPermission('manage menues') " 
                    @click="edit(item.id)">Edit</b-button>
                    <b-button           v-if=" $hasPermission('manage menues') " 
                    @click="deleteItem(item.id)" variant="danger">Delete</b-button>
                  </b-button-group>
                </b-button-toolbar>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-collapse>

    <b-modal
      id="topping-item-modal"
      size="lg"
      title="Add Topping"
      ref="topping-item-modal"
      hide-footer
    >
      <ToppingForm :group_id="topping.id" @success="get" :id="currentId"></ToppingForm>
    </b-modal>
  </div>
</template>


<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import ToppingForm from "@/view/pages/menu/form-components/ToppingForm";

export default {
  name: "ToppingGroupList",
  props: ['topping'],
  components: {
    ToppingForm,
  },
  data() {
    return {
      currentId: '',
    }
  },
  mounted() {
    //
  },
  methods: {
    get()
    {
      this.hideModal()
      this.$emit('success');
    },
    hideModal()
    {
      this.$refs["topping-item-modal"].hide();
    },
    addNew() {
      this.currentId = '';
      this.$refs["topping-item-modal"].show();
    },
    edit(id) {
      this.currentId = id;
      this.$refs["topping-item-modal"].show();
    },
    editGroup(id) {
      this.$emit('group-edit', id)
    },
    deleteItem(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('business/menu/toppings/'+id)
          .then(({ data }) => {
            this.get();
            Swal.fire(
              'Deleted!',
              'Topping has been deleted.',
              'success'
            )
          });
        }
      })
    },
    deleteGroup(id) {
      this.$emit('group-delete', id)
    }
  }
};
</script>
