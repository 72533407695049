<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        <span class="card-icon">
          <i class="flaticon2-chat-1 text-primary"></i>
        </span>
        <h3 class="card-label font-poppins">Menu Toppings Group</h3>
      </div>
      <div class="card-toolbar" style="display:grid;">
          <b-form-file
          v-model="toppingFile"
          placeholder="Choose a file to upload"
          drop-placeholder="Drop file here..."
        ></b-form-file>
        <button
          v-on:click="save"
          v-if="toppingFile != null"
          class="btn btn-success">
          <i class="flaticon2-add-1"></i>Upload Toppings
        </button>
      </div>
      <div class="card-toolbar">
        <a           v-if=" $hasPermission('manage menues') " 
         href="javascript:;" @click="addNew" class="btn btn-sm btn-success font-weight-bold">
        <i class="flaticon2-add-1"></i>Add Group</a>
      </div>
      
    </div>
    <div class="card-body p-0">
        <div class="row my-10 px-8 my-lg-15 px-lg-21">
          <div class="col-md-12">
            <b-list-group>
              <ToppingGroupList
                v-for="topping in toppings"
                :topping="topping"
                @group-edit="groupEdit"
                @group-delete="groupDelete"
                @success="get"
              />
            </b-list-group>
          </div>
        </div>
    </div>
    <b-modal id="topping-group-modal" ref="topping-group-modal" size="lg" title="Add Topping Group" hide-footer>
      <ToppingGroupForm :id="currentId" @success="get"></ToppingGroupForm>
    </b-modal>
  </div>
</template>


<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import ToppingGroupForm from "@/view/pages/menu/form-components/ToppingGroupForm";
import ToppingGroupList from "@/view/pages/menu/components/ToppingGroupList";

export default {
  name: "Toppings",
  components: {
    ToppingGroupForm,
    ToppingGroupList,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Menu" },
      { title: "Toppings" }
    ]);
    this.get();
  },
  data() {
    return {
      toppings: [],
      currentId: '',
      toppingFile : null,
    }
  },
  methods: {
    save() {
      let formData = new FormData();
      if (this.toppingFile != null)
      {
        formData.append('file', this.toppingFile);
        ApiService.fileUpload(
          'business/import/toppings',
          formData
        )
        .then(({ data }) => {
          if (data == 1) {
            Swal.fire("Success", "File Uploaded Successfully", "success");
            this.get();
          } else {
            Swal.fire("Error", data, "error");
          }
        })
        .catch(({ response }) => {
            console.log(response);
        });
      }
    },
    get() {
      this.hideModal()
      ApiService.get('business/menu/topping-groups')
      .then(({ data }) => {
        this.toppings = data.data;
      });
    },
    hideModal()
    {
      this.$refs["topping-group-modal"].hide();
    },
    groupEdit(id) {
      this.currentId = id;
      this.$refs["topping-group-modal"].show();
    },
    addNew() {
      this.currentId = '';
      this.$refs["topping-group-modal"].show();
    },
    groupDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('business/menu/topping-groups/'+id)
          .then(({ data }) => {
            this.get();
            Swal.fire(
              'Deleted!',
              'Topping Group has been deleted.',
              'success'
            )
          });
        }
      })
    },
  },
};
</script>
